

    <div class="theme-footer" id="mainFooter">
        <div class="container _ph-mob-0">
          <div class="row row-eq-height row-mob-full" data-gutter="60">
            <div class="col-lg-4">
              <div class="theme-footer-section theme-footer-">
                <a class="theme-footer-brand _mt-20" href="#">
                  <img src="assets/images/logo.webp" alt="Climax Coaches"  title="Image Title"/>
                </a>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-md-3">
                  <div class="theme-footer-section theme-footer-">
                    <h5 class="theme-footer-section-title">Our Company</h5>
                    <ul class="theme-footer-section-list">
                      <li>
                        <a [routerLink]="['/about-us']">About us</a>
                      </li>
                      <li>
                        <a [routerLink]="['/contact-us']">Contact us</a>
                      </li>
                      <li>
                        <a  [routerLink]="['/privacy-policy']">Terms & Conditions </a>
                      </li>
                      <li>
                        <a  [routerLink]="['/privacy-policy']">Privacy Policy</a>
                      </li>
                    </ul>
                  </div>
                </div>
    
                <div class="col-md-3 col-lg-3 col-sm-12">
                  <div class="theme-footer-section theme-footer-">
                    <h5 class="theme-footer-section-title">Our Contacts</h5>
                    <ul class="theme-footer-section-list">
                      <li>
                        <span class="phones-wrapper"><a class="contacts-links" href="tel:+254722584088">+254 722 584088 Nairobi</a> </span><br>

                      </li>
                      <li>
                        <h6 class="phone-titles"> Email:</h6>
                        <span class="phones-wrapper"><a href="mailto:climaxxcoaches&#64;gmail.com">climaxxcoaches&#64;gmail.com</a></span><br>
                        
                      </li>
    
                    </ul>
                  </div>
    
                </div >
    
                <div class="col-md-3 col-lg-3 col-sm-12 mobile-hidden">
                  <div class="theme-footer-section theme-footer-">
                    <h5 class="theme-footer-section-title">Top Routes</h5>
                    <ul class="theme-footer-section-list">
                      <li class="change-city">
                        <a (click)="clickTopCity('Nairobi','Busia')" >Nairobi - Busia</a>
                      </li>
                      <li class="change-city">
                        <a (click)="clickTopCity('Nairobi','Kisii')" >Nairobi - Kisii</a>
                      </li>
                      <li class="change-city">
                        <a (click)="clickTopCity('Nairobi','Migori')" >Nairobi - Migori </a>
                      </li>
                      <li class="change-city">
                        <a (click)="clickTopCity('Nairobi','Kitale')" >Nairobi - Kitale </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-3 col-lg-3 col-sm-12 mobile-app-cta">
                  <div class="_mt-40 mobile-app-cta-inner text-center">
                      <!-- <h3 class="_ml-10 ">GET THE APP!</h3>
                      <a class="centered-img" href="https://play.google.com/store/apps/details?id=io.mashpoamarketplace">
                        <img src="assets/img/company1/app-logo-GooglePlay.png" alt="Download on play store" class="icon-app-store">
                    </a> -->
                    <ul class="theme-copyright-social _mt-10">
                      <li>
                        <a class="fa fa-facebook icon-color-facebook fa-2x"  href="https://www.facebook.com/ClimaxCoaches/a" target="_blank"></a>
                      </li>
      
                      <li>
                        <a  href="https://twitter.com/climaxhonest?lang=en" target="_blank">
                          <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="35px" height="35px">
                            <path d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z"/>
                          </svg>
                        </a>
                      </li>
           
                    </ul>
                  </div>
                </div>
    
              </div>
              
            </div>

          </div>
        </div>
      </div>
      <div class="theme-copyright">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <p class="theme-copyright-text">Copyright &copy; 2023
                <a href="/">Climax Coaches</a>. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>

