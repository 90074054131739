import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService, DataService } from '../services/index'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LocationStrategy } from '@angular/common';
import { User,Error } from '../models/index'
import { UserService } from '../services/user.service'
import { Base64 } from 'js-base64';
@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {
  private sub: any;
  bookingpaymentdetails:any;
  returndetails:any;
  bookingdata:any={};
  return_trip:any;
  passengers=[];
  fsname:any;
  lsname:any;
  isdno:any;
  residence:any;
  phoneno:any;
  country:any;
  returnpassengers=[];
  bookingdataReturn:any={};
  formValidation=[];
  isError:boolean=false;
  errorMessage:any;
  res:any;
  is_return:false;
  loading=false;
  paymentForm: UntypedFormGroup;
  submitted = false;
  formDataObj={}
  copyMessage='Copy traveller 1 details';

  isLoggedIn:any;
  user:User;
  api_error:Error;
  loadingStart:boolean = false;
  resMsg:any
  accepted_terms=false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service:PaymentService,
    private dataService:DataService,
    private locationStrategy: LocationStrategy,
    private _fb: UntypedFormBuilder,
    private users:UserService
  ) { }

  ngOnInit() {
    this.preventBackButton();
    this.isLoggedIn = sessionStorage.getItem('isLoggedInEasy');
    if(this.isLoggedIn ){
      this.user=new User().deserialize(JSON.parse(sessionStorage.getItem('userEasy')))
      this.getUserProfile(this.user);
    }

    this.dataService.currentMessage.subscribe(message =>{

      if(!message){
        message = localStorage.getItem('payments-easycoach')
        if(!message) {
         this.router.navigate(['/'])
       }
      }else{
        localStorage.setItem('payments-easycoach', message)
      }

      let encoded =JSON.parse(Base64.decode(message))
      this.bookingpaymentdetails = JSON.parse(Base64.decode(encoded.travel));
      if(encoded.returning){
        this.returndetails = JSON.parse(Base64.decode(encoded.return));
        this.bookingpaymentdetails.totalfare=this.bookingpaymentdetails.totalfare + this.returndetails.totalfare;


      }

      console.log(this.bookingpaymentdetails.result.seats_types);

      this.is_return=encoded.returning;
      this.bookingpaymentdetails.seatsSelected.forEach((seat) => {
        let fsname='fname'+seat.id;
        let lsname='lname'+seat.id;
        let isdno='idno'+seat.id;
        let phoneno='phoneno'+seat.id;
        let residence='residence'+seat.id;
        this.formDataObj[fsname]=[this.user.first_name, [Validators.required,Validators.pattern('^[a-zA-Z ]+$'),Validators.minLength(3)]];
        this.formDataObj[lsname]=[this.user.last_name, [Validators.required,Validators.pattern('^[a-zA-Z ]+$'),Validators.minLength(3)]];
        this.formDataObj[phoneno]=[this.user.phone_number, [Validators.required, Validators.minLength(10),Validators.pattern('^(?:254|\\+254|0)(7|1)[0-9]{8}$')]];
        this.formDataObj[residence]=['Nairobi',Validators.required];
        this.formDataObj[isdno]=[this.user.id_number, [Validators.required, Validators.minLength(7)]];
      });
    });
    // this.formDataObj['email']=['', Validators.required];
    this.formDataObj['mobileNumber']=['', [Validators.required, Validators.minLength(10),Validators.pattern('^(?:254|\\+254|0)(7|1)[0-9]{8}$')]];
    this.paymentForm =this._fb.group(this.formDataObj);
  }






  getUserProfile(user:any){
    this.users.getProfile(user).subscribe(
      response => {
          this.loadingStart=false;
          this.resMsg=response;
          localStorage.setItem('userEasy',JSON.stringify(this.resMsg.data));
          sessionStorage.setItem('userEasy',JSON.stringify(this.resMsg.data));
      },
      error => {
        localStorage.removeItem("userEasy");
        // this.isLoggedIn=false;
      }
    );
  }

  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    })
  }

  get f() { return this.paymentForm.controls; }

copyDetails(){
  let seatno=0;
  let fsname: string, lsname: string, isdno: string, phoneno: string, residence:string;
  let passenger
    this.bookingpaymentdetails.seatsSelected.forEach((seat) => {
      seatno++;
      let passenger={};
      this.fsname='fname'+seat.id;
      this.lsname='lname'+seat.id;
      this.isdno='idno'+seat.id;
      this.phoneno = 'phoneno'+seat.id;
      this.residence='residence'+seat.id;
      if( seatno == 1){
        fsname=this.paymentForm.controls[this.fsname].value
        lsname=this.paymentForm.controls[this.lsname].value
        isdno=this.paymentForm.controls[this.isdno].value
        phoneno=this.paymentForm.controls[this.phoneno].value
        residence=this.paymentForm.controls[this.residence].value
      }

      if (seatno > 1){
        this.paymentForm.get(this.fsname).setValue(fsname);
        this.paymentForm.get(this.lsname).setValue(lsname);
        this.paymentForm.get(this.isdno).setValue(isdno);
        this.paymentForm.get(this.phoneno).setValue(phoneno);
        this.paymentForm.get(this.residence).setValue(residence);
      }
    });
    this.copyMessage ='Copied!'
    setTimeout(() => {
      this.copyMessage ='Copy traveller 1 details'
    }, 2000);

}

acceptedTerms(){
  this.accepted_terms=!this.accepted_terms;
}



submitForm(){
  this.submitted=true;
  if(!this.accepted_terms){
    return
  }

  if (this.paymentForm.valid) {
  let value = this.paymentForm.value;
  this.isError=false;
  this.loading=true;
  this.passengers=[];
  this.bookingpaymentdetails.seatsSelected.forEach((seat) => {
    let passenger={};
    this.fsname='fname'+seat.id;
    this.lsname='lname'+seat.id;
    this.isdno='idno'+seat.id;
    this.phoneno = 'phoneno'+seat.id;
    this.country = 'country'+seat.id;
    this.residence = 'residence'+seat.id;
passenger={
    "full_name": value[this.fsname] + ' ' + value[this.lsname],
    "id_or_passport": value[this.isdno].toString(),
    "seat": {
      "id": seat.id,
      "fare": parseInt(this.bookingpaymentdetails.totalfare) / this.bookingpaymentdetails.seatsSelected.length,
      "class": seat.space_type
    },
    "phone_number":  "0"+value[this.phoneno].substr(value[this.phoneno].length - 9),
    "residence":  value[this.residence],
}
this.passengers.push(passenger);
});
if(this.is_return){
this.returnpassengers=[];
this.returndetails.seatsSelected.forEach((seat) => {
  let passenger={};
  passenger={
    "full_name": value[this.fsname] + ' ' + value[this.lsname],
    "id_or_passport": value[this.isdno].toString(),
    "seat": {
      "id": seat.id,
      "fare": parseInt(this.bookingpaymentdetails.totalfare) / this.bookingpaymentdetails.seatsSelected.length,
      "class": seat.space_type
    },
    "phone_number":  "0"+value[this.phoneno].substr(value[this.phoneno].length - 9),
    "residence":  value[this.residence]
}
this.returnpassengers.push(passenger);
});
this.bookingdataReturn ={
    "booking_channel": "climax_website",
    "transaction_reference": "",
    "referral_code": "",
    "residence":"Nairobi",
    "promo_code": "",
    "selected_bus": {
      "from": this.returndetails.result.from,
      "to": this.returndetails.result.to,
      "departure_date": this.returndetails.result.departure_date,
      "bus_type_id": this.returndetails.result.bus_type_id,
      "operator": this.returndetails.result.operator,
      "is_express_travel": this.returndetails.result.is_express_travel,
      "number_of_booked_seats": this.returndetails.result.number_of_seats_booked,
      "id": this.returndetails.result.id,
      "make": this.returndetails.result.make,
      "model": this.returndetails.result.model,
      "route_id": this.returndetails.result.route_id,
      "route_schedule_id":  this.returndetails.result.route_schedule_id,
      "route_schedule_code":(this.bookingpaymentdetails.result.is_shuttle) ? '0' :  this.returndetails.result.route_schedule_code,
      "departure_time": this.returndetails.result.departure_time,
      "departure_timestamp": this.returndetails.result.departure_timestamp,
      "arrival_time": this.returndetails.result.arrival_time,
      "number_of_available_seats": this.returndetails.result.number_of_available_seats,
      "fare":parseInt(this.returndetails.totalfare) / this.returndetails.seatsSelected.length,
      "boarding_points": this.returndetails.result.boarding_points,
      "dropoff_points":this.returndetails.result.dropoff_points,
      "seats":this.returndetails.result.seats,
      "seat_types": this.returndetails.result.seats_types,
      "number_of_window_seats": this.returndetails.result.number_of_window_seats,
      "last_booking": this.returndetails.result.last_booking,
      "amenities":this.returndetails.result.amenities,
      "origin_city_id": this.returndetails.result.origin_city_id,
      "destination_city_id": this.returndetails.result.destination_city_id,
      "bus_capacity": this.returndetails.result.bus_capacity,
      "trip_id":this.returndetails.result.trip_id,
      "boarding_point": this.returndetails.pickup_location,
      "drop_off_point": this.returndetails.dropoff_location,
    },
    "payee": {
      "phone_number":value.mobileNumber,
        "name": value.fullname,
         "email": value.email
    },
    "boarding_point": this.returndetails.pickup_location,
    "drop_off_point": this.returndetails.dropoff_location,
    "date_of_travel": this.returndetails.result.departure_date,
    "departure_time": this.returndetails.result.departure_time,
    "arrival_time": this.returndetails.result.arrival_time,
    "route_schedule_id": this.returndetails.result.route_schedule_id,
    "operator": this.returndetails.result.operator,
    "origin_city_name":this.returndetails.result.from,
    "destination_city_name": this.returndetails.result.to,
    "origin_city_id":this.returndetails.result.origin_city_id,
    "destination_city_id": this.returndetails.result.destination_city_id,
    "passengers":  this.returnpassengers,
    "seatsSelected":this.returndetails.seatsSelected,
    "total_fare": this.returndetails.total_fare,
    "children": []
  }

  this.bookingdata ={
    "booking_channel": "climax_website",
    "transaction_reference": "",
    "referral_code": "",
    "residence":"Nairobi",
    "promo_code": "",
    "selected_bus": {
      "from": this.bookingpaymentdetails.result.from,
      "to": this.bookingpaymentdetails.result.to,
      "departure_date": this.bookingpaymentdetails.result.departure_date,
      "bus_type_id": this.bookingpaymentdetails.result.bus_type_id,
      "operator": this.bookingpaymentdetails.result.operator,
      "is_express_travel": this.bookingpaymentdetails.result.is_express_travel,
      "number_of_booked_seats": this.bookingpaymentdetails.result.number_of_seats_booked,
      "id": this.bookingpaymentdetails.result.id,
      "make": this.bookingpaymentdetails.result.make,
      "model": this.bookingpaymentdetails.result.model,
      "route_id": this.bookingpaymentdetails.result.route_id,
      "route_schedule_id":  this.bookingpaymentdetails.result.route_schedule_id,
      "route_schedule_code":(this.bookingpaymentdetails.result.is_shuttle) ? '0' :  this.bookingpaymentdetails.result.route_schedule_code,
      "departure_time": this.bookingpaymentdetails.result.is_shuttle ? '00:00:00' : this.bookingpaymentdetails.result.departure_time,
      "departure_timestamp": this.bookingpaymentdetails.result.departure_timestamp,
      "arrival_time": this.bookingpaymentdetails.result.is_shuttle ? '00:00:00' : this.bookingpaymentdetails.result.arrival_time,
      "number_of_available_seats": this.bookingpaymentdetails.result.number_of_available_seats,
      "fare": parseInt(this.bookingpaymentdetails.totalfare) / this.bookingpaymentdetails.seatsSelected.length,
      "boarding_points": this.bookingpaymentdetails.result.boarding_points,
      "dropoff_points":this.bookingpaymentdetails.result.dropoff_points,
      "seats":this.bookingpaymentdetails.result.seats,
      "seat_types": this.bookingpaymentdetails.result.seats_types,
      "number_of_window_seats": this.bookingpaymentdetails.result.number_of_window_seats,
      "last_booking": this.bookingpaymentdetails.result.last_booking,
      "amenities":this.bookingpaymentdetails.result.amenities,
      "origin_city_id":this.bookingpaymentdetails.result.origin_city_id,
      "destination_city_id": this.bookingpaymentdetails.result.destination_city_id,
      "bus_capacity": this.bookingpaymentdetails.result.bus_capacity,
      "trip_id":this.bookingpaymentdetails.result.trip_id,
    },
    "payee": {
      "phone_number":value.mobileNumber,
        "name": value.fullname,
         "email": value.email
    },
    "boarding_point": this.bookingpaymentdetails.pickup,
    "date_of_travel": this.bookingpaymentdetails.result.departure_date,
    "departure_time": this.bookingpaymentdetails.result.departure_time,
    "arrival_time": this.bookingpaymentdetails.result.arrival_time,
    "route_schedule_id":'' + this.bookingpaymentdetails.result.route_schedule_id,
    "operator": this.bookingpaymentdetails.result.operator,
    "origin_city_name":this.bookingpaymentdetails.result.from,
    "destination_city_name": this.bookingpaymentdetails.result.to,
    "origin_city_id":this.bookingpaymentdetails.result.origin_city_id,
    "destination_city_id": this.bookingpaymentdetails.result.destination_city_id,
    "passengers":  this.passengers,
    "children": []
  }

  // let bookingdata=Base64.encode(JSON.stringify(this.bookingdata));
  // let bookingdataReturn=Base64.encode(JSON.stringify(this.bookingdataReturn));
  // let bookingpaymentdetails=Base64.encode(JSON.stringify(this.bookingpaymentdetails));
  // let webformdata={
  //   'bookingdata' : bookingdata,
  //   'bookingpaymentdetails' : bookingpaymentdetails,
  //   'is_return':this.is_return,
  //   'returndata':  bookingdataReturn
  // }
  // console.log(this.bookingdataReturn);
  // let pay=Base64.encode(JSON.stringify(webformdata));
  // let paymessage=Base64.encode("Sorry!");
  // this.dataService.changeMessage(pay);
  // this.router.navigate(['/review-and-pay', paymessage])

  this.return_trip =  this.bookingdataReturn;
  


}
this.bookingdata ={
  "booking_channel": "climax_website",
  "transaction_reference": "",
  "referral_code": "",
  "residence":"Nairobi",
  "promo_code": "",
  "selected_bus": {
    "from": this.bookingpaymentdetails.result.from,
    "to": this.bookingpaymentdetails.result.to,
    "departure_date": this.bookingpaymentdetails.result.departure_date,
    "bus_type_id": this.bookingpaymentdetails.result.bus_type_id,
    "operator": this.bookingpaymentdetails.result.operator,
    "is_express_travel": ''+this.bookingpaymentdetails.result.is_express_travel,
    "number_of_booked_seats": this.bookingpaymentdetails.result.number_of_seats_booked,
    "id": ''+this.bookingpaymentdetails.result.id,
    "make": this.bookingpaymentdetails.result.make,
    "model": this.bookingpaymentdetails.result.model,
    "route_id": this.bookingpaymentdetails.result.route_id,
    "route_schedule_id": this.bookingpaymentdetails.result.route_schedule_id ,
    "route_schedule_code": (this.bookingpaymentdetails.result.is_shuttle) ? '0' : this.bookingpaymentdetails.result.route_schedule_code,
    "departure_time": (this.bookingpaymentdetails.result.is_shuttle) ? '00:00:00' : this.bookingpaymentdetails.result.departure_time,
    "departure_timestamp": this.bookingpaymentdetails.result.departure_timestamp,
    "arrival_time":(this.bookingpaymentdetails.result.is_shuttle) ? '00:00:00' : this.bookingpaymentdetails.result.arrival_time,
    "number_of_available_seats": this.bookingpaymentdetails.result.number_of_available_seats,
    "fare": parseInt(this.bookingpaymentdetails.totalfare) / this.bookingpaymentdetails.seatsSelected.length,
    "boarding_points": this.bookingpaymentdetails.result.boarding_points,
    "dropoff_points":this.bookingpaymentdetails.result.dropoff_points,
    "seats":this.bookingpaymentdetails.result.seats,
    "seat_types": this.bookingpaymentdetails.result.seats_types,
    "number_of_window_seats": this.bookingpaymentdetails.result.number_of_window_seats,
    "last_booking": this.bookingpaymentdetails.result.last_booking,
    "amenities":this.bookingpaymentdetails.result.amenities,
    "origin_city_id":this.bookingpaymentdetails.result.origin_city_id,
    "destination_city_id": this.bookingpaymentdetails.result.destination_city_id,
    "bus_capacity": this.bookingpaymentdetails.result.bus_capacity,
    "trip_id":this.bookingpaymentdetails.result.trip_id,
    "boarding_point": this.bookingpaymentdetails.pickup_location,
    "drop_off_point": this.bookingpaymentdetails.dropoff_location,
  },
  "payee": {
    "phone_number":value.mobileNumber,
      "name": value.fullname,
       "email": value.email
  },
  "boarding_point": this.bookingpaymentdetails.pickup_location,
  "drop_off_point": this.bookingpaymentdetails.dropoff_location,
  "date_of_travel": this.bookingpaymentdetails.result.departure_date,
  "departure_time": (this.bookingpaymentdetails.result.is_shuttle) ? '00:00:00' : this.bookingpaymentdetails.result.departure_time,
  "arrival_time": (this.bookingpaymentdetails.result.is_shuttle) ? '00:00:00' : this.bookingpaymentdetails.result.arrival_time,
  "route_schedule_id": this.bookingpaymentdetails.result.route_schedule_id,
  "operator": this.bookingpaymentdetails.result.operator,
  "origin_city_name":this.bookingpaymentdetails.result.from,
  "destination_city_name": this.bookingpaymentdetails.result.to,
  "origin_city_id":this.bookingpaymentdetails.result.origin_city_id,
  "destination_city_id": this.bookingpaymentdetails.result.destination_city_id,
  "passengers":  this.passengers,
  "children": []
}







let bookingData:any;

  if(this.is_return){
    this.bookingdata.payment_channel="Mpesa"
    this.return_trip.payment_channel="Mpesa"
    bookingData={
      "initial_trip":this.bookingdata,
      "return_trip":this.return_trip
    }
  }else{
    bookingData=this.bookingdata;
    this.bookingdata.payment_channel="Mpesa"
  }

  this.loading=true;
  this.service.submitBooking(bookingData).subscribe(
    data  => {
      this.res=data;
      if(this.res.status == true){
        let others={
          'data':this.res.data,
          'payment_type':"Mpesa",
          'trip_id':this.bookingpaymentdetails.result.trip_id,
          'fullname':this.bookingdata.payee.name,
          'email':this.bookingdata.payee.email,
          'phoneno':this.bookingdata.payee.phone_number,
          'depature_date':this.bookingpaymentdetails.result.departure_date,
          'departure_time':this.bookingpaymentdetails.result.departure_time,
          'arrival_time':this.bookingpaymentdetails.result.arrival_time,
          'from':this.bookingpaymentdetails.result.from,
          'to':this.bookingpaymentdetails.result.to,
          "passengers":  this.bookingdata.passengers,
          "pickup": this.bookingpaymentdetails.search_details.pickup,
          "dropoff": this.bookingpaymentdetails.search_details.dropoff,
          "operator": this.bookingpaymentdetails.result.operator,
          "is_shuttle":this.bookingpaymentdetails.result.is_shuttle,
          "boarding_point": this.bookingpaymentdetails.pickup_location,
          "drop_off_point": this.bookingpaymentdetails.dropoff_location,

        };
        let data= {
          "bookingpaymentdetails":this.bookingpaymentdetails,
          "confirm_data":others
        }

        let f=Base64.encode(JSON.stringify(data))
        this.router.navigate(['/confirm-payments', f])
      }else{
        this.res.
        this.loading=false;
        this.isError=true;
        this.errorMessage=this.res.message;
      }

    },
    error  => {

      this.loading=false;
      this.isError=true;
      if(error.status==500){
        this.errorMessage="Sorry there has been a technical problem.Please try again ";
      }else{
        this.api_error=new Error().getTheMessage(error.error.errors)
        this.errorMessage=this.api_error;
      }

    }

    );





}



}

}
