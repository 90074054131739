<app-headerhome class="header-sec"></app-headerhome>
<div class="progress-cntr-wrpr">
    <div class="progress-cntr">
        <div class="progress-tracker-cntr transparent">
            <div class="progress-tracker">
                <div class="progress-item first ">
                    <div class="item-wrapper passed">
                        <div class="progess-item-label">Booking</div>
                    </div>
                </div>
                <div class="progress-item current">
                    <div class="item-wrapper">
                        <div class="progess-item-label">Payment</div>
                    </div>
                </div>
                <div class="progress-item last">
                    <div class="item-wrapper">
                        <div class="progess-item-label">Ticket</div>
                    </div>
                </div>
                <div class="progress-tracker-completed" style="width: 0%;"></div>
            </div>
        </div>
    </div>
</div>
<!-- ================================
    START BOOKING AREA
================================= -->
<section class="booking-area padding-top-50px padding-bottom-70px">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <form id="ngForm" [formGroup]="paymentForm" (ngSubmit)="submitForm()">
                <div class="form-box">
                    <div class="form-title-wrap">
                        <h3 class="title">Booking Submission</h3>
                    </div><!-- form-title-wrap -->
                    <div class="row">
                        <div class="col-md-11">
                          <button *ngIf="bookingpaymentdetails.seatsSelected.length > 1" type="button" class="btn btn-primary-invert btn-shadow text-upcase theme-footer-subscribe-btn pull-right" name="button" (click)="copyDetails()">
                            <i class="fa fa-clipboard" aria-hidden="true"></i>  {{copyMessage}}
                          </button>
                        </div>
                    </div>
                    <div class="form-content ">
                        <div class="contact-form-action">
                                <div *ngFor="let seat of bookingpaymentdetails.seatsSelected; let seatno = index">
                                   <p class="text-right"> <b>Passenger {{seatno+1}} </b> </p>
                                    <div class="row" >
                                        <div class="col-lg-6 responsive-column">
                                            <div class="input-box">
                                                <label class="label-text">First Name</label>
                                                <div class="form-group">
                                                    <span class="la la-user form-icon"></span>
                                                    <input class="form-control" 
                                                    type="text" 
                                                    name="fname{{seat.id}}"
                                                    id="fname{{seatno+1}}"
                                                    value="{{user.first_name}}"
                                                    autocomplete="off"
                                                    formControlName="fname{{seat.id}}" 
                                                    placeholder="First name">
                                                </div>
                                                <small class="invalid-select" *ngIf="paymentForm.get('fname'+seat.id).touched && paymentForm.get('fname'+seat.id).hasError('pattern')">First name can contain Numbers or Special characters</small>
                                                <small class="invalid-select" *ngIf="paymentForm.get('fname'+seat.id).touched && paymentForm.get('fname'+seat.id).hasError('required')">Your first name is required!</small>
                                                <small class="invalid-select" *ngIf="paymentForm.get('fname'+seat.id).touched && paymentForm.get('fname'+seat.id).hasError('minlength')">Your first name should be correct!</small>
                                            </div>
                                        </div><!-- end col-lg-6 -->
                                        <div class="col-lg-6 responsive-column">
                                            <div class="input-box">
                                                <label class="label-text">Last Name</label>
                                                <div class="form-group">
                                                    <span class="la la-user form-icon"></span>
                                                    <input class="form-control"
                                                    type="text"
                                                    name="lname{{seat.id}}"
                                                    id="lname{{seatno+1}}"
                                                    autocomplete="off"
                                                    formControlName="lname{{seat.id}}"
                                                    placeholder="Last name">
                                                </div>
                                                <small class="invalid-select" *ngIf="paymentForm.get('lname'+seat.id).touched && paymentForm.get('lname'+seat.id).hasError('pattern')">Last name can contain Numbers or Special characters</small>
                                                <small class="invalid-select" *ngIf="paymentForm.get('lname'+seat.id).touched && paymentForm.get('lname'+seat.id).hasError('required')">Your last name is required!</small>
                                                <small class="invalid-select" *ngIf="paymentForm.get('lname'+seat.id).touched && paymentForm.get('lname'+seat.id).hasError('minlength')">Your last name should be correct!</small>
                                                                    
                                            </div>
                                        </div><!-- end col-lg-6 -->
                                        <div class="col-lg-6 responsive-column">
                                            <div class="input-box">
                                                <label class="label-text">ID/Passport</label>
                                                <div class="form-group">
                                                    <span class="la la-envelope-o form-icon"></span>
                                                    <input class="form-control" 
                                                    type="text"
                                                    name="idno{{seat.id}}"
                                                    id="idno{{seatno+1}}"
                                                    autocomplete="off"
                                                    formControlName="idno{{seat.id}}"
                                                    placeholder="ID No or Passport">
                                                </div>
                                                <small class="invalid-select" *ngIf="paymentForm.get('idno'+seat.id).touched && paymentForm.get('idno'+seat.id).hasError('minlength')">Your Id/passport should be valid</small>
                                                <small class="invalid-select" *ngIf="paymentForm.get('idno'+seat.id).touched && paymentForm.get('idno'+seat.id).hasError('required')">Your Id/passport  is required!</small>
                                                
                                            </div>
                                        </div><!-- end col-lg-6 -->
                                        <div class="col-lg-6 responsive-column">
                                            <div class="input-box">
                                                <label class="label-text">Residence</label>
                                                <div class="form-group">
                                                    <span class="la la-map-marker form-icon"></span>
                                                    <input class="form-control" 
                                                    type="text"
                                                    name="residence{{seat.id}}"
                                                    id="residence{{seatno+1}}"
                                                    autocomplete="off"
                                                    formControlName="residence{{seat.id}}"
                                                    placeholder="Residence">
                                                </div>
                                                <small class="invalid-select" *ngIf="paymentForm.get('residence'+seat.id).touched && paymentForm.get('residence'+seat.id).hasError('pattern')">Your residence should not contain numbers</small>
                                                <small class="invalid-select" *ngIf="paymentForm.get('residence'+seat.id).touched && paymentForm.get('residence'+seat.id).hasError('required')">This field is required!</small>
                                                
                                            </div>
                                        </div><!-- end col-lg-6 -->
                                        <div class="col-lg-6 responsive-column">
                                            <div class="input-box">
                                                <label class="label-text">Passenger phone number</label>
                                                <div class="form-group">
                                                    <span class="la la-phone form-icon"></span>
                                                    <input class="form-control" 
                                                    type="text"
                                                    name="phoneno{{seat.id}}"
                                                    id="phoneno{{seatno+1}}"
                                                    formControlName="phoneno{{seat.id}}"
                                                    placeholder="e.g 0722000000">
                                                </div>
                                                <small class="invalid-select" *ngIf="paymentForm.get('phoneno'+seat.id).touched && paymentForm.get('phoneno'+seat.id).hasError('minlength')">Your phone number should not be less or greater than 10</small>
                                                <small class="invalid-select" *ngIf="paymentForm.get('phoneno'+seat.id).touched && paymentForm.get('phoneno'+seat.id).hasError('required')">Your phone number field is required!</small>
                                                <small class="invalid-select" *ngIf="paymentForm.get('phoneno'+seat.id).touched && paymentForm.get('phoneno'+seat.id).hasError('pattern')">Your phone number should be valid!</small>

                                            </div>
                                        </div><!-- end col-lg-6 -->

                                    </div>
                                </div>
                        </div><!-- end contact-form-action -->
                    </div><!-- end form-content -->
                </div><!-- end form-box -->
                <div class="form-box">
                    <div class="form-title-wrap">
                        <h3 class="title">Your payment Information</h3>
                    </div><!-- form-title-wrap -->
                    <div class="form-content">
                        <div class="tab-content">
                            <div class="tab-pane fade show active" id="credit-card" role="tabpanel" aria-labelledby="credit-card-tab">
                                <div class="contact-form-action">
                                        <div class="row">
                                            <div class="col-lg-8">
                                                <div class="input-box">
                                                    <label class="label-text">Mpesa number (Your ticket SMS will be sent to this number)</label>
                                                    <div class="form-group">
                                                        <span class="la la-phone form-icon"></span>
                                                        <input class="form-control" 
                                                        name="mobileNumber"
                                                        type="text"
                                                        id="mobileNumber"
                                                        formControlName="mobileNumber"
                                                        placeholder="Safaricom mpesa no">
                                                    </div>
                                                    <small class="invalid-select" *ngIf="paymentForm.get('mobileNumber').touched && paymentForm.get('mobileNumber').hasError('minlength')">Your mpesa number should not be less than 10. </small>
                                                    <small class="invalid-select" *ngIf="paymentForm.get('mobileNumber').touched && paymentForm.get('mobileNumber').hasError('required')">Your mpesa number required!</small>
                                                    <small class="invalid-select" *ngIf="paymentForm.get('mobileNumber').touched && paymentForm.get('mobileNumber').hasError('pattern')">Your mpesa number should be valid!</small>
                                                </div>
                                            </div><!-- end col-lg-6 -->
                                            <div class="disclaimer">Please Note: Once seats are selected
                                                and paid for, they cannot be changed. Tickets are non-refundable.
                                              </div>
                                            <div class="col-lg-12 _mt-10">
                                                <div class="input-box">
                                                    <div class="form-group">
                                                        <div class="custom-checkbox">
                                                            <input type="checkbox" id="agreechb">
                                                            <label for="agreechb"
                                                            type="checkbox" 
                                                            name="terms" 
                                                            (click)="acceptedTerms()"
                                                            required=""
                                                            >By submitting my personal data, I consent to Climax Coaches Limited collecting, processing, and storing my information in accordance with the 
                                                            <a routerLink="/privacy-policy" target="_blank"> <u>Climax Coaches Privacy Policy</u></a>.</label>
                                                            <small class="invalid-select" *ngIf=" submitted && !this.accepted_terms">Please accept the terms and condition</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><!-- end col-lg-12 -->
                                            <div class="col-lg-12">
                                                
                                                 
                                                <p class="invalid-select">{{errorMessage}}</p>
                                                <div class="btn-box">
                                                    <button  form="ngForm"  [ngClass]="( paymentForm.valid) ? 'enabled':'disabled'"  [disabled]="paymentForm.invalid" class="theme-btn border-theme" type="submit">
                                                        <div class="centered-img">
                                                            <img *ngIf="!loading"  src="/assets/img/company1/mpesa-logo.png" />
                                                            <span>   <b *ngIf="loading">Processing.....</b>  <b *ngIf="!loading">PAY WITH MPESA</b></span>
                                                          </div>
                                                    </button>
                                                </div>
                                            </div><!-- end col-lg-12 -->
                                        </div>
                                </div><!-- end contact-form-action -->
                            </div><!-- end tab-pane-->

                        </div><!-- end tab-content -->
                    </div><!-- end form-content -->
                </div><!-- end form-box -->
            </form>
            </div><!-- end col-lg-8 -->
            <div class="col-lg-4">
                <div class="form-box booking-detail-form">
                    <div class="form-title-wrap">
                        <h3 class="title">Your Booking</h3>
                    </div><!-- end form-title-wrap -->
                    <div class="form-content">
                        <div class="card-item shadow-none radius-none mb-0">
                            <div class="card-img pb-4">
                                <a href="room-details.html" class="d-block">
                                    <img src="assets/images/about/about_climax.webp" alt="room-img">
                                </a>
                            </div>
                            <div class="card-body p-0">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <h3 class="card-title">{{bookingpaymentdetails.pickup_location}} - {{bookingpaymentdetails.dropoff_location}}</h3>
                                        <p class="card-meta">{{bookingpaymentdetails.traveldate}}</p>
                                    </div>
                                    <div>
                                        <a href="room-details.html" class="btn ml-1"><i class="la la-edit" data-toggle="tooltip" data-placement="top" title="Edit"></i></a>
                                    </div>
                                </div>
                                <div class="section-block"></div>
                               
                                <h3 class="card-title pb-3">Order Details</h3>
                                <div class="section-block"></div>
                                <ul class="list-items list-items-2 py-3">
                                    <li><span>Passenger(s):</span>{{ bookingpaymentdetails.seatsSelected.length }} </li>
                                </ul>
                                <div class="section-block"></div>
                                <ul class="list-items list-items-2 pt-3">
                                    <li><span>Total Price:</span>Ksh {{bookingpaymentdetails.totalfare}}</li>
                                </ul>
                            </div>
                        </div><!-- end card-item -->
                    </div><!-- end form-content -->
                </div><!-- end form-box -->
            </div><!-- end col-lg-4 -->
        </div><!-- end row -->
    </div><!-- end container -->
</section><!-- end booking-area -->
<!-- ================================
    END BOOKING AREA
================================= -->

<div class="section-block"></div>

<!-- ================================
    START INFO AREA
================================= -->
<section class="info-area info-bg padding-top-90px padding-bottom-70px">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 responsive-column">
                <a href="#" class="icon-box icon-layout-2 d-flex">
                    <div class="info-icon flex-shrink-0 bg-rgb text-color-2">
                        <i class="la la-phone"></i>
                    </div><!-- end info-icon-->
                    <div class="info-content">
                        <h4 class="info__title">Need Help? Contact us</h4>
                        <ul class="list-items">
                            <li>
                              <a href="#"
                                ><i class="la la-phone mr-1"></i>020 2210711</a
                              >
                            </li>
                            <li>
                              <a href="#"
                                ><i class="la la-envelope mr-1"></i
                                >info&#64;climaxcoaches.com</a
                              >
                            </li>
                          </ul>
                    </div><!-- end info-content -->
                </a><!-- end icon-box -->
            </div><!-- end col-lg-4 -->
            <div class="col-lg-4 responsive-column">
                <a href="#" class="icon-box icon-layout-2 d-flex">
                    <div class="info-icon flex-shrink-0 bg-rgb-2 text-color-3">
                        <i class="la la-money"></i>
                    </div><!-- end info-icon-->
                    <div class="info-content">
                        <h4 class="info__title">Payments</h4>
                        <p class="info__desc">
                           Pay with mpesa
                        </p>
                    </div><!-- end info-content -->
                </a><!-- end icon-box -->
            </div><!-- end col-lg-4 -->
            <div class="col-lg-4 responsive-column">
                <a href="#" class="icon-box icon-layout-2 d-flex">
                    <div class="info-icon flex-shrink-0 bg-rgb-3 text-color-4">
                        <i class="la la-times"></i>
                    </div><!-- end info-icon-->
                    <div class="info-content">
                        <h4 class="info__title">Cancel Policy</h4>
                        <p class="info__desc">
                            Once seats are selected and paid for, they cannot be changed. Tickets are non-refundable
                        </p>
                    </div><!-- end info-content -->
                </a><!-- end icon-box -->
            </div><!-- end col-lg-4 -->
        </div><!-- end row -->
    </div><!-- end container -->
</section><!-- end info-area -->
<!-- ================================
    END INFO AREA
================================= -->
<app-footer></app-footer>
