    <!-- ================================
            START HEADER AREA
================================= -->
<header class="header-area ">
  <div class="header-top-bar padding-right-100px padding-left-100px _mob-h">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="header-top-content">
            <div class="header-left">
              <ul class="list-items">
                <li>
                  <a href="tel:+254722584088"
                    ><i class="la la-phone mr-1"></i> +254 722 584 088</a
                  >
                </li>
                <li>
                  <a href="#"
                    ><i class="la la-envelope mr-1"></i
                    >climaxxcoaches&#64;gmail.com </a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="header-top-content">
            <div
              class="header-right d-flex align-items-center justify-content-end"
            >
             
              <div class="header-right-action">
                <a *ngIf="!isLoggedIn"
                  href="#"
                  class="theme-btn theme-btn-small theme-btn-transparent mr-1"
                  data-toggle="modal"
                  data-target="#signupPopupForm"
                  >Sign Up</a
                >
                <a *ngIf="!isLoggedIn"
                  href="#"
                  class="theme-btn theme-btn-small"
                  data-toggle="modal"
                  data-target="#loginPopupForm"
                  >Login</a
                >
                <div  *ngIf="isLoggedIn" class="dropdown">
                  <a href="#" class="dropdown-toggle" id="userDropdownMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <div class="d-flex align-items-center">
                          <div class="avatar avatar-sm flex-shrink-0 mr-2"><img src="assets/images/user1.jpeg" alt="team-img"></div>
                         
                      </div>
                  </a>
                  <div class="dropdown-menu dropdown-reveal dropdown-menu-md dropdown-menu-right" style="">
                      <div class="dropdown-item drop-reveal-header user-reveal-header">
                          <h6 class="title text-uppercase">Welcome!</h6>
                      </div>
                      <div class="list-group drop-reveal-list user-drop-reveal-list">
                          <a [routerLink]="['/user-profile']" class="list-group-item list-group-item-action">
                              <div class="msg-body">
                                  <div class="msg-content">
                                      <h3 class="title"><i class="la la-user mr-2"></i>My Profile</h3>
                                  </div>
                              </div><!-- end msg-body -->
                          </a>
                          <a [routerLink]="['/my-booking']" class="list-group-item list-group-item-action">
                              <div class="msg-body">
                                  <div class="msg-content">
                                      <h3 class="title"><i class="la la-shopping-cart mr-2"></i>My Booking</h3>
                                  </div>
                              </div><!-- end msg-body -->
                          </a>
                         
                          <div class="section-block"></div>
                          <a  (click)="logoutUser()" class="list-group-item list-group-item-action">
                              <div class="msg-body">
                                  <div class="msg-content">
                                      <h3 class="title"><i class="la la-power-off mr-2"></i>Logout</h3>
                                  </div>
                              </div><!-- end msg-body -->
                          </a>
                      </div>
                  </div><!-- end dropdown-menu -->
              </div>

              </div>

            </div>
          </div>
        </div>
      </div>
     
    </div>
  </div>
  <div class="header-menu-wrapper padding-right-100px padding-left-100px">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="menu-wrapper">
            <a href="#" class="down-button"
              ><i class="la la-angle-down"></i
            ></a>
            <div class="logo">
              <a href="index.html"
                ><img src="assets/images/logo.webp"  alt="logo"
              /></a>
              <div class="menu-toggler">
                <i class="la la-bars"></i>
                <i class="la la-times"></i>
              </div>
              <!-- end menu-toggler -->
            </div>
            <!-- end logo -->
            <div class="main-menu-content">
              <nav>
                <ul>
                  <li><a href="index.html">Home</a></li>
                  <li><a [routerLink]="['/bus-hire']" >Bus hire</a></li>
                  <li><a [routerLink]="['/parcel']">Parcels</a></li>
                  <li><a [routerLink]="['/contact-us']"  href="#">Contact us</a></li>
                  <li><a [routerLink]="['/about-us']"  href="#">About us</a></li>
                  <li><a href="https://www.facebook.com/ClimaxCoaches/" target="_blank"><i class="lab la-facebook-f icon-color-facebook fa-1x "></i></a></li>
                  <li><a href="https://twitter.com/climaxhonest?lang=en" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="25px" height="25px">
                      <path d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z"/>
                    </svg>
                  </a></li>
                  <!-- <li>
                    <a href="#">About us <i class="la la-angle-down"></i></a>
                    <ul class="dropdown-menu-item">
                      <li><a href="#">Booking offices</a></li>
                      <li><a href="#">About us</a></li>
                      
                    </ul>
                  </li> -->
                 
       
                </ul>
              </nav>
            </div>
         
          </div>
          <!-- end menu-wrapper -->
        </div>
        <!-- end col-lg-12 -->
      </div>
      <!-- end row -->

    </div>
    <!-- end container-fluid -->
  </div>

  <!-- end header-menu-wrapper -->
</header>

<!-- ================================
     END HEADER AREA
================================= -->