import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PaymentsComponent } from './payments/payments.component';
import { HomeComponent } from './home/home.component';
import { BookingComponent } from './booking/booking.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { SuccessComponent } from './success/success.component';
import { ContactusComponent } from './contactus/contactus.component';
import { CarhireComponent } from './carhire/carhire.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ParcelComponent } from './parcel/parcel.component';

import { UserProfileComponent } from './user-profile/user-profile.component';
import { MyBookingComponent } from './my-booking/my-booking.component';
import { CitiesFromComponent } from './mobile/cities-from/cities-from.component'

import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { CustomerFeedbackComponent } from './customer-feedback/customer-feedback.component';
import { FullCalendarComponent } from './mobile/full-calendar/full-calendar.component';
import { CheckPaymentComponent } from './check-payment/check-payment.component';
import { ProcessRetryComponent } from './process-retry/process-retry.component';
import { RetrySuccessfulComponent } from './retry-successful/retry-successful.component';

const routes: Routes = [
  // { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'booking/:id',      component: BookingComponent },
  { path: 'payments/:id',      component: PaymentsComponent },
  { path: 'pay/:id',      component: CheckPaymentComponent, data: {animation: 'Payments'}, },
  { path: 'process/pay/:id',      component: ProcessRetryComponent, data: {animation: 'Payments'}, },
  { path: 'retry/successful/:id',      component: RetrySuccessfulComponent, data: {animation: 'Payments'}, },
  { path: 'booking-successful/:id',      component: SuccessComponent },
  { path: 'confirm-payments/:id',      component: ConfirmComponent },
  { path: 'about-us',      component: AboutusComponent },
  { path: 'contact-us',      component: ContactusComponent },
  { path: 'bus-hire',      component: CarhireComponent },
  { path: 'parcel',      component: ParcelComponent },
  { path: 'user-profile',      component: UserProfileComponent },
  { path: 'my-booking',      component: MyBookingComponent },
  { path: 'privacy-policy',      component: PrivacyPolicyComponent },
  { path: 'terms-and-condition',      component: TermsConditionComponent },
  { path: 'customer-feedback-form',      component: CustomerFeedbackComponent },
  { path: 'select-city', component: CitiesFromComponent },
  { path: 'full-page-calendar/:dateType', component: FullCalendarComponent },
  { path: '**', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { 
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload',
    scrollOffset: [0, 0],
      // Enable scrolling to anchors
      anchorScrolling: "enabled",
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
