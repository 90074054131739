
    <div id="checkout-ebuupass" class="buupass-sandbox">

            <div id="checkout-ebuupass"
                class="buupass-context-popup buupass-overlay checkout-background-color-black buupass-logo-color-white">
                <a href="#" class="buupass-close" aria-label="close" role="button"></a>
                <div class="buupass-modal">
                    <div class="buupass-logo">
                            <div class="provider-info">
                                    <h4 class="text-black">Processing your payments please wait ....</h4>
                                    <div class="ixigo-logo u-ib "><img
                                            src="assets/img/greenline/mpesa-logo.png"></div>
                                    <div class="loader-img u-ib">
                                            <svg xmlns:svg="http://www.w3.org/2000/svg"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" width="152px" height="20px" viewBox="0 0 457 60" xml:space="preserve">
                                            <g>
                                                <circle fill="#007929" cx="-31" cy="30" r="30"/>
                                                <circle fill="#329353" cx="-97" cy="30" r="24"/>
                                                <circle fill="#64ae7d" cx="-163" cy="30" r="19"/>
                                                <circle fill="#c8e2d1" cx="-229.5" cy="30.5" r="13.5"/>
                                                <circle fill="#e1efe6" cx="-295" cy="31" r="11"/>
                                                <animateTransform attributeName="transform" type="translate" values="61 0;127 0;193 0;259 0;325 0;391 0;457 0;523 0;589 0;655 0;721 0;787 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;" calcMode="discrete" dur="1280ms" repeatCount="indefinite"/>
                                            </g>
                                            <g>
                                                <circle fill="#007929" cx="488" cy="30" r="30"/>
                                                <circle fill="#329353" cx="554" cy="30" r="24"/>
                                                <circle fill="#64ae7d" cx="620" cy="30" r="19"/>
                                                <circle fill="#c8e2d1" cx="686.5" cy="30.5" r="13.5"/>
                                                <circle fill="#e1efe6" cx="753" cy="31" r="11"/>
                                                <animateTransform attributeName="transform" type="translate" values="0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;-61 0;-127 0;-193 0;-259 0;-325 0;-391 0;-457 0;-523 0;-589 0;-655 0;-721 0;-787 0;" calcMode="discrete" dur="1280ms" repeatCount="indefinite"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="provider-logo u-ib">
                                        <img  class="mobile-images _mt-10" alt="Greenline" src="assets/images/logo.webp">
                                    </div>
                                </div>

                    </div>
                    <div *ngIf="isError" class="buupass-message seat-book-error text-red">{{errorMessage}}.&nbsp; </div>
                    <div *ngIf="isWaiting" class="buupass-message seat-book-waiting text-green">{{waitingMessage}}.&nbsp; </div>
                    <div class="buupass-continue">
                            <button (click)="requestConfirm()" class="theme-btn w-100 text-center margin-top-20px">CONFIRM</button>
                            <button (click)="cancelConfirm()" class="theme-btn btn-red w-100 text-center margin-top-20px">CANCEL</button>
                    </div>
                    <div class="buupass-loader">
                        <div class="buupass-spinner"></div>
                    </div>
                </div>
                <div class="buupass-iframe-container">
                    <div class="zoid-outlet"></div>
                </div>

            </div>
        </div>
