
<app-headerhome class="header-sec"></app-headerhome>

<section class="breadcrumb-area bread-bg-2">
  <div class="breadcrumb-wrap">
      <div class="container">
          <div class="row">
              <div class="col-lg-12">
                  <div class="breadcrumb-content text-center">
                      <div class="section-heading">
                          <h2 class="sec__title text-white">Package Delivery  </h2>
                          <p class="text-white">Serving innumerable locations in Kenya and beyond                          </p>
                      </div>
                  </div><!-- end breadcrumb-content -->
              </div><!-- end col-lg-12 -->
          </div><!-- end row -->
      </div><!-- end container -->
  </div><!-- end breadcrumb-wrap -->
  <div class="bread-svg-box">
      <svg class="bread-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 10" preserveAspectRatio="none"><polygon points="100 0 50 10 0 0 0 10 100 10"></polygon></svg>
  </div><!-- end bread-svg -->
</section>

<section class="listing-form section--padding">
  <div class="container">
      <div class="row">
          <div class="col-lg-9 mx-auto">
             
              <div class="form-box">
                  <div class="form-title-wrap">
                      <h3 class="title text-center">Striving hard to make the delivery experience of our clients worth remembering </h3>
                      <p class="text-center _pt-10">For parcel inquiry reach us on <a href="mailto:climaxxcoaches&#64;gmail.com"> climaxxcoaches&#64;gmail.com</a></p>
                  </div><!-- form-title-wrap -->
                  <div class="_p-20">
                    <p class="font-size-14 ">It is hard to trust on someone when it comes to handling your precious items. 
                      Imagine a company maintaining complete confidentiality with your parcel? Isn’t it fascinating? 
                      If yes then it’s time to leave all the worries aside because Climax Parcels, has put its best foot forward. 
                      We are a trusted agency striving hard to make the delivery experience of our clients worth remembering.</p>
                    <p class="font-size-14 ">Door-to-door or counter-to-counter, we provide the best combination of service and 
                      value in expedited, time-critical package delivery. Serving innumerable locations in Kenya and beyond.</p>
                      <div class="form-title-wrap">
                        <img class="img-responsive img-fluid" src="assets/images/parcel2.webp" alt="Climax Coaches Parcel">
                      </div>
                  </div>
                  <div class="_p-20">
                  <h3 class="title text-center">A trustworthy and user-friendly delivery system for its clients</h3>
                  <p class="font-size-14">Climax Parcel Services aims at providing its clients a great service at an affordable price and a mission to build a 
                    trustworthy and user-friendly delivery system for its clients.</p>
                    <ol class="font-size-14" style="list-style-type:disc;padding-left:2em;font-size: 1.1944em;">
                      <li>We provide on-time delivery.</li>
                      <li>We are available 24/7, just a call away.</li>
                      <li>We have a well-trained staff working hard to provide you with the best services.</li>
                      <li>We take up your live location in order to avoid mistakes in the address.</li>
                      <li>Climax caravans situated at various locations.</li>
                    </ol>
                    </div>

                    
            
              </div><!-- end form-box -->

           
          </div><!-- end col-lg-9 -->
      </div><!-- end row -->
  </div><!-- end container -->
</section>
<app-footer></app-footer>