<div class="home-wrapper">
    <app-headerhome class="header-sec "></app-headerhome>

    <!-- ================================
    START HERO-WRAPPER AREA
================================= -->
<section class="_desk-h">
  <div class=" _desk-h _pt-80">
    <app-form ></app-form>
  </div>
</section>

<section class="hero-wrapper hero-wrapper8 _mt-100 p-5 _mob-h">
  <div class="row justify-content-center position-relative p-0 m-0">
		<!-- start heading -->
		<div class="col-md-12  position-relative" >
			<img  src="./assets/Menu sections/climax4.webp" class="img-fluid border-img"
				alt="Front Image">
			<div  class="position-absolute top-0 start-10 text-white p-3 ms-3">
				
			</div>


		</div>
		<!-- End heading -->

		<!-- strat travel information -->
		<div class="col-12 px-2 px-md-5 pt-3 card-container" >
			<div class="Frmcard panel--border card row p-0 m-0 p-4">
				<div class="col-12 col-md-12 0">
          <div class="row ">
						<ul class="nav nav-tabs " id="myTab" role="tablist">
						
							<div class="col-md-2">
								<li class="nav-item  " role="presentation">
									<button class="nav-link active" id="bus-tab"
										data-bs-toggle="tab" data-bs-target="#bus" type="button" role="tab"
										aria-controls="bus" aria-selected="false">
										<img class="mx-2"
											src="assets/Menu sections/bus/bus.png" alt="">
										
										Bus</button>
								</li>
							</div>







							
						</ul>

					</div>
					<!-- navbar start -->
					<div class="tab-content mt-4" id="myTabContent">
						<div class="tab-pane fade active show" id="bus" role="tabpanel" aria-labelledby="bus-tab">
							<!-- bus content -->
              <div class="section-tab section-tab-2 pb-2">
                <ul class="nav nav-tabs" id="myTab3" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="one-way-tab"
                      data-toggle="tab"
                      href="#one-way"
                      role="tab"
                      aria-controls="one-way"
                      aria-selected="true"
                    >
                      One way
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="round-trip-tab"
                      data-toggle="tab"
                      href="#round-trip"
                      role="tab"
                      aria-controls="round-trip"
                      aria-selected="false"
                    >
                      Round Trip
                    </a>
                  </li>
                </ul>
              </div>
              <div class="tab-content" id="myTabContent3">
                <div
                  class="tab-pane show active"
                  id="one-way"
                  role="tabpanel"
                  aria-labelledby="one-way-tab"
                >
							<div class="row mt-3">
								<div class="col-md-12">
                    <form autocomplete="off" novalidate [formGroup]="reactiveForm" (ngSubmit)="submitForm()" class=" d-flex gap-3">
										<div class="tab-field-box">
											<label for="exampleInputEmail1"
												class="form-label common_labels mx-3 mt-1">Leaving from</label>
                        <input type="text" name="pickup" formControlName="pickup"  (click)="clearInput('pickup')" class="form-control common_inputs" [matAutocomplete]="autoPickup">
                        <mat-autocomplete #autoPickup="matAutocomplete">
                          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                            {{ option }}
                          </mat-option>
                         
                        </mat-autocomplete>
                        <!-- Validation Error Messages -->
                      
											<img class="arrow-img" src="./assets/Menu sections/input.png"
												alt="Connection Image" />
                        <span *ngIf=" submitted && f.pickup.errors ">
                          <span class="text-danger" *ngIf="submitted && f.pickup.errors.required">
                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            Please enter pickup location.
                          </span>
                        </span>
										</div>

										<div class="tab-field-box">
											<label for="exampleInputEmail2"
												class="form-label common_labels mx-3 mt-1">Going
												to</label>
										
                        <input  name="dropoff" id="aantal" formControlName="dropoff"  (click)="clearInput('dropoff')" class="form-control common_inputs" [matAutocomplete]="autoDropoff">
                            <mat-autocomplete #autoDropoff="matAutocomplete">
                              <mat-option *ngFor="let option of filteredDropoffOptions | async" [value]="option">
                                {{ option }}
                              </mat-option>
                            </mat-autocomplete>
                            <span *ngIf=" submitted && f.dropoff.errors ">
                              <span class="text-danger" *ngIf="submitted && f.dropoff.errors.required">
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                Please enter dropoff location.
                              </span>
                            </span>
										</div>

										<div class="tab-field-box">
											<label for="exampleInputEmail1"
												class="form-label common_labels mx-3 mt-1">Departure date</label>
								
                        <input required autocomplete="off" type="text"
                        placeholder="Travel Date"
                        class="form-control common_inputs"
                        formControlName="traveldate"
                        [minDate]="today"
                        #dp="bsDatepicker"
                        bsDatepicker
                        [bsConfig]="{ isAnimated: true,adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY' }"
                        [bsValue]="bsInlineValue">
                        <span *ngIf=" submitted && f.traveldate.errors ">
                          <span class="text-danger" *ngIf="submitted && f.traveldate.errors.required">
                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            Please select travel date.
                          </span>
                        </span>
										
										</div>
                    <!-- <div class="row mt-5">
                      <div class="col-md-6 text-right">
                        <button class="btn-lg theme-btn btn-light search_btn_booking "
                          (click)="submitForm()">Search</button>
                      </div>
  
                    </div> -->
                    <div class="col-lg-2">
                      <button
                        type="submit" 
                        class="theme-btn border-theme w-100 text-center "
                        >Search</button>
                    </div>

									</form>
								
								</div>
							</div>
              </div>
           
              
                <div
                  class="tab-pane "
                  id="round-trip"
                  role="tabpanel"
                  aria-labelledby="one-way-tab"
                >
							<div class="row mt-3">
								<div class="col-md-12">
                    <form autocomplete="off" novalidate [formGroup]="returnForm" (ngSubmit)="submitReturnForm()" class=" d-flex gap-3">
										<div class="tab-field-box">
											<label for="exampleInputEmail1"
												class="form-label common_labels mx-3 mt-1">Leaving from</label>
                        <input type="text" name="pickupReturn" formControlName="pickupReturn"  (click)="clearInput('pickupReturn')" class="form-control common_inputs" [matAutocomplete]="autoPickupReturn">
                        <mat-autocomplete #autoPickupReturn="matAutocomplete">
                          <mat-option *ngFor="let option of returnfilteredOptions | async" [value]="option">
                            {{ option }}
                          </mat-option>
                        </mat-autocomplete>
											<img class="arrow-img" src="./assets/Menu sections/input.png"
												alt="Connection Image" />
                        <span *ngIf=" submittedReturn && r.pickupReturn.errors ">
                          <span class="text-danger" *ngIf="submittedReturn && r.pickupReturn.errors.required">
                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            Please enter pickup location.
                          </span>
                        </span>
										</div>

										<div class="tab-field-box">
											<label for="exampleInputEmail2"
												class="form-label common_labels mx-3 mt-1">Going
												to</label>
                        <input  name="dropoffReturn" id="aantal" formControlName="dropoffReturn"  (click)="clearInput('dropoffReturn')" class="form-control common_inputs" [matAutocomplete]="autoDropoffReturn">
                            <mat-autocomplete #autoDropoffReturn="matAutocomplete">
                              <mat-option *ngFor="let option of returnfilteredDropoffOptions | async" [value]="option">
                                {{ option }}
                              </mat-option>
                            </mat-autocomplete>
                            <span *ngIf=" submittedReturn && r.dropoffReturn.errors ">
                              <span class="text-danger" *ngIf="submittedReturn && r.dropoffReturn.errors.required">
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                Please enter dropoff location.
                              </span>
                            </span>
										
										</div>

										<div class="tab-field-box">
											<label for="exampleInputEmail1"
												class="form-label common_labels mx-3 mt-1">Departure date</label>
                        <input required autocomplete="off" type="text"
                        placeholder="Travel Date"
                        class="form-control common_inputs"
                        formControlName="traveldateReturn"
                        [minDate]="today"
                        #dp="bsDatepicker"
                        bsDatepicker
                        [bsConfig]="{ isAnimated: true,adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY' }"
                        [bsValue]="bsInlineValue">
                        <span *ngIf=" submittedReturn && r.traveldateReturn.errors ">
                          <span class="text-danger" *ngIf="submittedReturn && r.traveldateReturn.errors.required">
                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            Please select travel date.
                          </span>
                        </span>
										</div>
                    <div class="tab-field-box">
											<label for="exampleInputEmail1"
												class="form-label common_labels mx-3 mt-1">Return date</label>
                        <input required autocomplete="off" type="text"
                        placeholder="Travel Date"
                        class="form-control common_inputs"
                        formControlName="returndate"
                        [minDate]="today"
                        #dp="bsDatepicker"
                        bsDatepicker
                        [bsConfig]="{ isAnimated: true,adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY' }"
                        [bsValue]="bsInlineValue">
                        <span *ngIf=" submittedReturn && r.returndate.errors ">
                          <span class="text-danger" *ngIf="submittedReturn && r.returndate.errors.required">
                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            Please select return travel date.
                          </span>
                        </span>
										
										</div>
                    <!-- <div class="row mt-5">
                      <div class="col-md-6 text-right">
                        <button class="btn-lg theme-btn btn-light search_btn_booking "
                          (click)="submitForm()">Search</button>
                      </div>
  
                    </div> -->
                    <div class="col-lg-2">
                      <button
                        type="submit" 
                        class="theme-btn border-theme w-100 text-center "
                        >Search</button>
                    </div>

									</form>
								
								</div>
							</div>
              </div>
            </div>
            
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</section>
<!-- end hero-wrapper -->
<!-- ================================
END HERO-WRAPPER AREA
================================= -->

<!-- ================================
START INFO AREA
================================= -->


<section
  class="info-area info-bg  padding-bottom-40px text-center bg-gray"
>
  <div class="container">
     <!-- <div  class="xmas-img-web _mt-20   _mob-h" style="">
      <img  height="146" src="assets/images/offer.webp">
    </div>
    <div  class="xmas-img-mobile  _mt-10  _desk-h" style="">
      <img class="img-fluid" src="assets/images/mobileoffer.webp">
    </div>   -->
  <div class="row">
      <!-- end col-lg-3 -->
      <div class="col-lg-4">
        <div class="icon-box">
          <div class="info-icon after-none before-none font-size-40">
            <a href="#" class="" data-toggle="modal" data-target="#comfortOnboard"><i class="la la-bus"></i></a>
            
          </div>
          <!-- end info-icon-->
          <div class="info-content pt-0">
            <h4 class="info__title font-size-16 font-weight-bold">
              Top-tier Service
            </h4>
            <p>
              Enjoy a top-tier service that defines excellence in travel, ensuring your journey is nothing short of exceptional.
            </p>
          </div>
          <!-- end info-content -->
        </div>
        <!-- end icon-box -->
      </div>
      <!-- end col-lg-4 -->
      <div class="col-lg-4">
        <div class="icon-box">
          <div class="info-icon after-none before-none font-size-40">
            <a href="#" class="" data-toggle="modal" data-target="#largestBusNetwork"><i class="la la-wifi"></i></a>
          </div>
          <!-- end info-icon-->
          <div class="info-content pt-0">
            <h4 class="info__title font-size-16 font-weight-bold">
              On-the-Go Connectivity
            </h4>
            <p>
              Enjoy On-the-Go Connectivity for uninterrupted travel convenience, keeping you connected.
            </p>
            
          </div>
          <!-- end info-content -->
        </div>
        <!-- end icon-box -->
      </div>
      <!-- end col-lg-4 -->
      <div class="col-lg-4">
        <div class="icon-box">
          <div class="info-icon after-none before-none font-size-40">
            <a href="#" class="" data-toggle="modal" data-target="#enviromentFriendly"><i class="la la-user-shield"></i></a>
          </div>
          <!-- end info-icon-->
          <div class="info-content pt-0">
            <h4 class="info__title font-size-16 font-weight-bold">
              Safety First
            </h4>
            <p>
              At Climax Coaches, it's 'Safety First' for your worry-free journey.
            </p>
            
          </div>
          <!-- end info-content -->
        </div>
        <!-- end icon-box -->
      </div>
      <!-- end col-lg-3 -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</section>
<!-- ================================
END INFO AREA
================================= -->

<section class="destination-area padding-top-100px padding-bottom-80px">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="section-heading text-center">
          <h2 class="sec__title font-size-30">Top Destinations</h2>
        </div>
        <!-- end section-heading -->
      </div>
      <!-- end col-lg-12 -->
    </div>
    <!-- end row -->
    <div class="row padding-top-60px">
      <div   class="col-lg-4 responsive-column change-city">
        <div (click)="clickTopCity('Nairobi','Busia')" class="card-item flight-card">
          <div class="card-img">
            <div class="d-block">
              <img src="assets/images/busia.webp" alt="destination-img">
              <span class="badge">Busia</span>
            </div>
          </div>
          <div class="card-body">
            <h3 class="card-title">
              <div>Nairobi to Busia</div>
            </h3>
           
          </div>
        </div>
        <!-- end card-item -->
      </div>
        <div  (click)="clickTopCity('Nairobi','Kisii')" class="col-lg-4 responsive-column change-city">
        <div class="card-item flight-card">
          <div class="card-img">
            <div class="d-block">
              <img src="assets/images/kisii.webp" alt="destination-img">
              <span class="badge">Kisii</span>
            </div>
          </div>
          <div class="card-body">
            <h3 class="card-title">
              <div>Nairobi to Kisii</div>
            </h3>
            
          </div>
        </div>
        <!-- end card-item -->
      </div>
            <!-- end col-lg-4 -->
            <div (click)="clickTopCity('Nairobi','Migori')" class="col-lg-4 responsive-column change-city">
              <div class="card-item flight-card">
                <div class="card-img">
                  <div class="d-block">
                    <img src="assets/images/migori.webp" alt="destination-img">
                    <span class="badge">Migori</span>
                  </div>
                </div>
                <div class="card-body">
                  <h3 class="card-title">
                    <div>Nairobi to Migori</div>
                  </h3>
                 
                </div>
              </div>
              <!-- end card-item -->
            </div>
      <!-- end col-lg-4 -->
      <div (click)="clickTopCity('Nairobi','Kitale')"  class="col-lg-4 responsive-column change-city">
        <div class="card-item flight-card">
          <div class="card-img">
            <div class="d-block">
              <img src="assets/images/kitale.webp" alt="destination-img">
              <span class="badge">Kitale</span>
            </div>
          </div>
          <div class="card-body">
            <h3 class="card-title">
              <div>Nairobi to Kitale</div>
            </h3>
          
          </div>
        </div>
        <!-- end card-item -->
      </div>
            <!-- end col-lg-4 -->
            <div (click)="clickTopCity('Nairobi','Malaba')"  class="col-lg-4 responsive-column change-city">
              <div class="card-item flight-card">
                <div class="card-img">
                  <div class="d-block">
                    <img src="assets/images/malaba.webp" alt="destination-img">
                    <span class="badge">Malaba</span>
                  </div>
                </div>
                <div class="card-body">
                  <h3 class="card-title">
                    <div>Nairobi to Malaba</div>
                  </h3>
                 
                </div>
              </div>
              <!-- end card-item -->
            </div>
      <!-- end col-lg-4 -->
      <div (click)="clickTopCity('Kisumu','Nairobi')"  class="col-lg-4 responsive-column change-city">
        <div class="card-item flight-card">
          <div class="card-img">
            <div class="d-block">
              <img src="assets/images/nairobi.webp" alt="destination-img">
              <span class="badge">Kisumu</span>
            </div>
          </div>
          <div class="card-body">
            <h3 class="card-title">
              <div>Kisumu to Nairobi</div>
            </h3>
           
          </div>
        </div>
        <!-- end card-item -->
      </div>
      <!-- end col-lg-4 -->
    


      <!-- end col-lg-4 -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</section>




      <!-- end testimonial-area -->

<section>
        <!-- end modal-shared -->
        <div class="modal-popup">
          <div
            class="modal fade"
            id="comfortOnboard"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <div>
                    <h5 class="modal-title title" id="exampleModalLongTitle">
                      Comfort on board
                    </h5>
                    
                  </div>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" class="la la-close"></span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="contact-form-action">
                  <img src="assets/images/easycoachseats.webp" alt="kericho" />
                  </div>
                  <!-- end contact-form-action -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end modal-popup -->
                <!-- end modal-shared -->
                <div class="modal-popup">
                  <div
                    class="modal fade"
                    id="largestBusNetwork"
                    tabindex="-1"
                    role="dialog"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <div>
                            <h5 class="modal-title title" id="exampleModalLongTitle">
                              Largest bus network
                            </h5>
                            
                          </div>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true" class="la la-close"></span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <div class="contact-form-action">
                          <img src="assets/images/easycoach-map.webp" alt="kericho" />
                          </div>
                          <!-- end contact-form-action -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end modal-popup -->

                                <!-- end modal-shared -->
                                <div class="modal-popup">
                                  <div
                                    class="modal fade"
                                    id="enviromentFriendly"
                                    tabindex="-1"
                                    role="dialog"
                                    aria-hidden="true"
                                  >
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                      <div class="modal-content">
                                        <div class="modal-header">
                                          <div>
                                            <h5 class="modal-title title" id="exampleModalLongTitle">
                                              Travel environmentally-friendly
                                            </h5>
                                            
                                          </div>
                                          <button
                                            type="button"
                                            class="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                          >
                                            <span aria-hidden="true" class="la la-close"></span>
                                          </button>
                                        </div>
                                        <div class="modal-body">
                                          <div class="contact-form-action">
                                          <img src="assets/images/easycoach-map.webp" alt="kericho" />
                                          </div>
                                          <!-- end contact-form-action -->
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <!-- end modal-popup -->

                

        
</section>

<div class="section-block"></div>


<div class="section-block"></div>
    <app-footer></app-footer>
</div>
