import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function strongPasswordValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null; // Don't validate empty value
    }
    const hasUpperCase = /[A-Z]+/.test(value);
    const hasLowerCase = /[a-z]+/.test(value);
    const hasNumeric = /[0-9]+/.test(value);
    const hasSpecial = /[\W]+/.test(value); // or specific characters /[!@#$%^&*()_+=-]+/

    const valid = hasUpperCase && hasLowerCase && hasNumeric && hasSpecial;
    return !valid ? { strongPassword: true } : null;
  };
}

