import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, FormControl } from '@angular/forms';
import {Destinations } from '../models/index'
import {HomeService,DataService } from '../services/index'
import { formatDate, PlatformLocation  } from '@angular/common';
import {Router, NavigationEnd} from "@angular/router"
import { Base64 } from 'js-base64';

import { map, startWith } from 'rxjs/operators';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  bsInlineValue = new Date();
  reactiveForm: UntypedFormGroup;
  returnForm: UntypedFormGroup;
  destinationLoading=false;
  pickup='';
  dropoff='';
  From ="From";
  To ="To";
  traveldate='';
  destinations: Destinations[] = [];
  destination: any = {};
  res: any = {};
  today=new Date();
  bsValue = new Date();
  public pickplaceholder: string = 'Nairobi';
  public destplaceholder: string = 'Kisumu';
  public returnpickplaceholder: string = 'Nairobi';
  public returndestplaceholder: string = 'Kisumu';
  public historyHeading: string = 'Recently selected';
  keyword = 'name';
  isClicked=false;
  isPickupClicked=false;
  returning=false;
  isReadOnly=false;
  //traveldate:any;
  data = [];
  destinationslists=[];
  msg = '';
  formdata={};
  submitted:boolean = false;
  submittedReturn:boolean = false;
  citires:any;
  loading: boolean;
  form = new UntypedFormGroup({
    traveldate: new UntypedFormControl(new Date()),
  });

  //material forms intergration
  myControl = new FormControl();
  testoptions: string[] = ['One', 'Two', 'Three', 'Four', 'Five'];
  filteredOptions: Observable<string[]>;
  filteredDropoffOptions: Observable<string[]>;
  returnfilteredOptions: Observable<string[]>;
  returnfilteredDropoffOptions: Observable<string[]>;
  private optionsSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  options$: Observable<string[]> = this.optionsSubject.asObservable();
  optionsDropoff$: Observable<string[]> = this.optionsSubject.asObservable();
  returnoptions$: Observable<string[]> = this.optionsSubject.asObservable();
  optionsReturnDropoff$: Observable<string[]> = this.optionsSubject.asObservable();
  defaultCities = [];



  constructor(
    private service : HomeService,
    private dataService : DataService,
    private router:Router,
    private _fb: UntypedFormBuilder
        ) {
          this.reactiveForm = _fb.group({
            pickup:['', Validators.required],
            dropoff:['', Validators.required],
            traveldate:[new Date(), Validators.required],
            // returndate:['']
          });

          this.returnForm = _fb.group({
            pickupReturn:['', Validators.required],
            dropoffReturn:['', Validators.required],
            traveldateReturn:[new Date(), Validators.required],
            returndate:[new Date(), Validators.required]
          });


       
        
    }
  ngOnInit() {
    this.loadDestinations();

    // this.filteredOptions = combineLatest([
    //   this.pickupControl.valueChanges.pipe(startWith('')),
    //   this.options$
    // ]).pipe(
    //   map(([value, options]) => this._filter(value, options))
    // );

    this.filteredOptions = combineLatest([
      this.pickupControl.valueChanges.pipe(startWith('')),
      this.options$
  ]).pipe(
      map(([value, options]) => {
          // If the input is empty, show default cities first
          if (value === '') {
              return this.defaultCities.concat(options.filter(option => !this.defaultCities.includes(option)));
          } else {
              return this._filter(value, options);
          }
      })
  );

    this.filteredDropoffOptions = combineLatest([
      this.dropoffControl.valueChanges.pipe(startWith('')),
      this.optionsDropoff$
    ]).pipe(
      map(([value, options]) => this._filterDropoff(value, options))
    );

    this.returnfilteredOptions = combineLatest([
      this.returnPickupControl.valueChanges.pipe(startWith('')),
      this.returnoptions$
    ]).pipe(
      map(([value, options]) => this._filter(value, options))
    );

    this.returnfilteredDropoffOptions = combineLatest([
      this.returnDropoffControl.valueChanges.pipe(startWith('')),
      this.optionsReturnDropoff$
    ]).pipe(
      map(([value, options]) => this._filter(value, options))
    );


  }

  private _filter(value: string, options: string[]): string[] {
    const filterValue = value.toLowerCase();
    return options.filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filterDropoff(value: string, options: string[]): string[] {
    const filterValue = value.toLowerCase();
    const filteredList = options.filter(option => option.toLowerCase().includes(filterValue));
    return this.defaultCities.concat(filteredList.filter(city => !this.defaultCities.includes(city)));
    // return options.filter(option => option.toLowerCase().includes(filterValue));
  }

  get f() { return this.reactiveForm.controls; }
  get r() { return this.returnForm.controls; }

  get pickupControl() {
    return this.reactiveForm.get('pickup');
  }

  get dropoffControl() {
    return this.reactiveForm.get('dropoff');
  }

  get returnPickupControl() {
    return this.returnForm.get('pickupReturn');
  }

  get returnDropoffControl() {
    return this.returnForm.get('dropoffReturn');
  }
  
  

  clearInput(which) {
    if(which === 'pickup'){
      this.pickupControl.setValue('');
    }else if(which ==='dropoff'){
      this.dropoffControl.setValue('');
    }else if(which ==='pickupReturn'){
      this.returnPickupControl.setValue('');
    }else{
      this.returnDropoffControl.setValue('');
    }
  }

  selectEvent(item) {
    // do something with selected item
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e){
    this.isClicked=true;
  }

  returnClicked =() =>{
    this.returning=true;
  }

  closeReturn(){
    this.returning=false;
  }

  onClosed(e){
    this.isClicked=false;
  }

  onPickupFocused(e){
    this.isClicked=true;
    this.isPickupClicked=true;

  }
  onPickupClosed(e){
    this.isClicked=false;
    this.isPickupClicked=false;
  }





clickTopCity(depature,destination){
  var traveldate = formatDate(this.today,"yyyy-MM-dd","en-KE");
  let kisumu={
      'pickup' : depature,
      'dropoff' : destination,
      'pickupname' :depature,
      'dropoffname' : destination,
      'traveldate' : traveldate,
      'returndate': '',
      'returning' : this.returning
    }
    let p=Base64.encode(JSON.stringify(kisumu));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking', traveldate])
}

clickOffer(depature,destination,date){

  var traveldate = formatDate(date,"yyyy-MM-dd","en-KE");
  let kisumu={
      'pickup' : depature,
      'dropoff' : destination,
      'pickupname' :depature,
      'dropoffname' : destination,
      'traveldate' : traveldate,
      'returndate': '',
      'returning' : this.returning
    }

    let p=Base64.encode(JSON.stringify(kisumu));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking', traveldate])

}


  submitForm() {

    this.submitted = true;
     let value = this.reactiveForm.value;
    var traveldate = formatDate(value.traveldate,"yyyy-MM-dd","en-KE");
    value.traveldate=traveldate;
    var returndate='';

    if (this.reactiveForm.invalid ) {
      return;
    }
    if(this.returning){
      var returndate = formatDate(value.returndate,"yyyy-MM-dd","en-KE");
       value.returndate=returndate;
    }
    this.formdata={
      'pickup' : value.pickup,
      'dropoff' : value.dropoff,
      'pickupname' : value.pickup,
      'dropoffname' : value.dropoff,
      'traveldate' : traveldate,
      'returndate': returndate,
      'returning' : this.returning
    }


    let p=Base64.encode(JSON.stringify(this.formdata));
    this.dataService.changeMessage(p);

    this.router.navigate(['/booking', traveldate])
  }

  submitReturnForm() {
    this.submittedReturn = true;
    if (this.returnForm.invalid ) {
      return;
    }
     let value = this.returnForm.value;
    var traveldate = formatDate(value.traveldateReturn,"yyyy-MM-dd","en-KE");
    value.traveldateReturn=traveldate;
    var returndate = formatDate(value.returndate,"yyyy-MM-dd","en-KE");
    value.returndate=returndate;
    this.formdata={
      'pickup' : value.pickupReturn,
      'dropoff' : value.dropoffReturn,
      'pickupname' : value.pickupReturn,
      'dropoffname' : value.dropoffReturn,
      'traveldate' : traveldate,
      'returndate': returndate,
      'returning' : true
    }


    let p=Base64.encode(JSON.stringify(this.formdata));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking', traveldate])
  }

  private loadDestinations() {

    this.destinationLoading=true;

    this.service.getDestinations().subscribe(
      response => {
        let list: string[] = [];
        this.res = response;
          this.res.data.all_cities.forEach(element => {
              list.push(element.name);
          
          });
        this.data=list;
        this.destinationslists=list;
        this.optionsSubject.next(list);
        let cities=Base64.encode(JSON.stringify(this.data));
        localStorage.setItem('climax-cities', cities);
        this.destinationLoading=false;
      },
      error => {

      }
    );

  }

}
